import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

// const truncate = (input) =>
//   `${input.substring(0, 10)}...`;

  var truncate = function (fullStr, separator) {
    separator = separator || '...';
    return fullStr.substr(0, 6) + 
           separator + 
           fullStr.substr(38, 6);
           
};

export const StyledButton = styled.button`
  padding: 20px;
  border: 1px solid white;
  background-color: #0b0c12;
  padding: 20px;
  font-weight: bold;
  font-size: 30px;
  color: var(--secondary-text);
  
  cursor: pointer;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
`;

export const StyledButtonSave = styled.button`
  padding: 20px;
  border: 2px solid white;
  background-color: #0b0c12;
  padding: 20px;
  font-weight: bold;
  font-size: 20px;
  color: var(--secondary-text);
  
  cursor: pointer;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 45px;
`;

export const StyledButtonConnect = styled.button`
  border: 2px none var(--secondary-text);
  background-color: #00000049;
  padding: 15px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 150px;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
`;

export const StyledButtonMedia = styled.button`
  border: 2px none var(--secondary-text);
  background-color: #00000049;
  padding-right: 10px;
  padding-bottom: 6px;
  padding-top: 8px;
  padding-left: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
`;

export const StyledButtonMedia2 = styled.button`
  border: 2px none var(--secondary-text);
  background-color: #00000049;
  padding-right: 4px;
  padding-bottom: 2px;
  padding-top: 6px;
  padding-left: 4px;
  font-weight: bold;
  color: var(--secondary-text);
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
`;

export const StyledLogo = styled.img`
  width: 150px;
  @media (min-width: 767px) {
    width: 150px;
  }
  @media (min-width: 900px) {
    width: 200px;
  }
  @media (min-width: 1000px) {
    width: 250px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  
  border: 2px none #505050;
  width: 100%;
  max-width: 400px;
`;

export const StyledImgDemo = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 2px none #ffffff2a;
  border-radius: 30px;
  width: 20%;
`;

export const StyledImgRoadmap = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 2px none #ffffff2a;
  border-radius: 30px;
  width: 100%;
`;

export const StyledImgTeam = styled.img`
  width: 15%;
`;

export const StyledLink = styled.a`
  color: var(--secondary-text);
  text-decoration: none;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  
`;

export const StyledLinkMenu = styled.a`
  color: var(--secondary-text);
  text-decoration: none;
  font-size: 20px;
`;


function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
 
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost);
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Preparing your ${CONFIG.SYMBOL}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Error, please try again");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Congratulations ! You just minted a ${CONFIG.NFT_NAME}.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen >
      <s.ContainerMenu style={{ paddingTop: 24, paddingRight: 34, paddingLeft: 34 }}>
          <StyledButtonMedia>
          <a target={"_blank"} href="https://twitter.com/THIEFFFZZZ"><img src="/config/images/x-white.png" title="twitter logo" width="30" height="30" /></a> 
		  </StyledButtonMedia>
      <s.SpacerXLarge />
      <StyledButtonMedia2>
          <a target={"_blank"} href="https://dexscreener.com/solana/DYiwfc7ipvRFarMMWL45U2VVjJw7GJJqnrfXNR7ZyMBC"><img src="/config/images/dexscreener.png" title="dexscreener logo" width="40" height="40" /></a> 
		  </StyledButtonMedia2>
      <s.SpacerXLarge />
		  <StyledButtonMedia>
          <a target={"_blank"} href="https://t.me/fThiefzOnSolana"><img src="/config/images/tglogo.png" title="tg logo" width="30" height="30" /></a> 
		  </StyledButtonMedia>
		  
		  
          </s.ContainerMenu>
		  <s.ContainerOutro>
          <s.TextTitle
            >
            <s.SpacerLarge />
            💲THIEƒZ💲
          </s.TextTitle> 
		  </s.ContainerOutro>
      <s.ContainerOutro>
          <s.TextSubTitle
            >
            
			<StyledLink  
              target={"_blank"} href={CONFIG.SCAN_LINK}>
               {CONFIG.CONTRACT_ADDRESS}
              </StyledLink>
          </s.TextSubTitle> 
		  
          </s.ContainerOutro>
		  <s.Container flex={0} ai={"center"} style={{ padding: 24}}>
        
			
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/LOGO V1.png"}/>
          </s.Container>
          
        </s.Container> 
		  
		  <s.SpacerSmall />
		  <s.ContainerOutro>
		  <StyledButton>
		  <StyledLink  
              target={"_blank"} href="https://beta.raydium.io/swap/?inputMint=sol&outputMint=DYiwfc7ipvRFarMMWL45U2VVjJw7GJJqnrfXNR7ZyMBC">
                BUY on Raydium
              </StyledLink>
			  </StyledButton>
			  </s.ContainerOutro>
          
		  
        
		
      <s.ContainerOutro style={{ padding: 24}}>
          <s.TextSubTitle
            style={{ textAlign: "center", color: "var(--primary-text)", padding: 12, boxShadow: "0px 5px 11px 2px rgba(0, 0, 0, 0.7)", backgroundColor: "#0b0c12", whiteSpace: 'pre-line'}}
          >
            
            Who never met a Thieƒ ? {"\n"}
            <s.SpacerXSmall/>
            <s.SpacerXSmall/>
            ƒ*ck the THIEVES  {"\n"} 
            
            
          </s.TextSubTitle> 
          
        </s.ContainerOutro>
        
        
        
		
    </s.Screen>
	
  );
}

export default App;
